@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

@media (min-width: 0) {
  html, body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    cursor: url(/static/media/General-Cursor.c22e4651.svg), auto;
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
  }
}

@media (min-width: 0) {
  .App-Wrapper {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 0) {
  nav {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 769px) {
  nav {
    display: flex;
  }
}

.Portrait-Div {
  height: 100vh;
  width: 100vw;
  background-color: #9b251f;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 10%;
  pointer-events: none;
}
.Portrait-Div p:nth-of-type(1) {
  color: #FFE9DD;
  font-weight: 700;
  font-size: 1.2em;
}
.Portrait-Div p:nth-of-type(2) {
  font-size: 0.8em;
  color: #FFE9DD;
  margin: 0 15% 0 15%;
  text-align: center;
}

@media (min-width: 0) {
  .Portrait-Texture {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}

.Portrait-Icon {
  height: 30%;
  opacity: 0.5;
  margin-top: 43%;
}

@media (min-width: 0) {
  .App-Fullscreen {
    display: none;
    position: absolute;
    z-index: 6;
    background-color: Transparent;
    border: 2px solid white;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 0.5em;
    bottom: 2%;
    left: 1.5%;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
  }
  .App-Fullscreen:hover {
    background-color: #0d0d0d;
    border: 3px solid #0d0d0d;
    color: white;
  }
}
@media (min-width: 769px) {
  .App-Fullscreen {
    display: flex;
    padding: 5px 10px;
    font-size: 0.5em;
  }
}
@media (min-width: 1100px) {
  .App-Fullscreen {
    padding: 10px 20px;
    font-size: 0.7em;
  }
}
@media (min-width: 1442px) {
  .App-Fullscreen {
    padding: 12px 22px;
    font-size: 0.9em;
  }
}

.Portrait-Icon {
  -webkit-animation: rotateIcon 3s infinite;
  animation: rotateIcon 3s infinite;
}

@-webkit-keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(-90deg);
  }
}
@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(-90deg);
  }
}
.fadeIn {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeOut {
  -webkit-animation: fadeOut 0.5s forwards;
  animation: fadeOut 0.5s forwards;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@media (min-width: 0) {
  .First-Wrapper {
    background: #0d0d0d;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
  }
}

@media (min-width: 0) {
  .First-Texture {
    height: 100%;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    pointer-events: none;
  }
}

@media (min-width: 0) {
  .First-TextLoad {
    align-items: center;
    color: #F23E2E;
    display: flex;
    font-size: 2em;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    height: 100vh;
    justify-content: center;
    position: absolute;
    width: 100vw;
    z-index: 3;
  }
}
@media (min-width: 769px) {
  .First-TextLoad {
    font-size: 3em;
  }
}
@media (min-width: 1100px) {
  .First-TextLoad {
    font-size: 3.5em;
  }
}

@media (min-width: 0) {
  .First-TextWarning {
    align-items: center;
    color: #FFE9DD;
    display: flex;
    font-size: 0.8em;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    z-index: 4;
    text-align: center;
  }
  .First-TextWarning p:first-of-type {
    padding-right: 25%;
    padding-left: 25%;
  }
  .First-TextWarning p:nth-of-type(2) {
    text-align: center;
    text-decoration: underline;
    transition: 0.3s;
    margin-top: 10px;
  }
  .First-TextWarning p:nth-of-type(2):hover {
    transition: 0.3s;
    opacity: 0.4;
    cursor: pointer;
  }
}
@media (min-width: 0) and (min-width: 769px) {
  .First-TextWarning {
    font-size: 1em;
  }
}
@media (min-width: 0) and (min-width: 1100px) {
  .First-TextWarning {
    font-size: 1.2em;
  }
}
@media (min-width: 0) and (min-width: 1442px) {
  .First-TextWarning {
    font-size: 1.5em;
  }
  .First-TextWarning p:first-of-type {
    padding-right: 30%;
    padding-left: 30%;
  }
}

@media (min-width: 0) {
  .First-Background {
    background: url(/static/media/First-Background.7dbfd7b0.jpg);
    background-size: cover;
    height: 100vh;
    opacity: 0.6;
    width: 100vw;
    z-index: 2;
  }
}

.Second-WrapperWhite {
  background-color: #FFE9DD;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.Second-WrapperRed {
  background-color: #F23E2E;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

@media (min-width: 0) {
  .Second-ButtonDiv {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 0) {
  .Second-Button {
    max-height: 120px;
    max-width: 140px;
    opacity: 0.7;
    position: absolute;
    transition: 0.3s ease-out;
    bottom: 10vh;
    z-index: 5;
  }
  .Second-Button:hover {
    max-height: 140px;
    max-width: 160px;
    opacity: 1;
    transition: 0.3s ease-in;
    cursor: pointer;
  }
}
@media (min-width: 769px) {
  .Second-Button {
    max-width: 150px;
  }
  .Second-Button:hover {
    max-width: 170px;
  }
}
@media (min-width: 1100px) {
  .Second-Button {
    max-width: 170px;
  }
  .Second-Button:hover {
    max-width: 190px;
  }
}
@media (min-width: 1442px) {
  .Second-Button {
    max-width: 240px;
  }
  .Second-Button:hover {
    max-width: 260px;
  }
}

@media (min-width: 0) {
  .Second-TitleDiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    top: 38vh;
    left: 0;
    position: absolute;
  }
}
@media (min-width: 769px) {
  .Second-TitleDiv {
    top: 0;
    align-items: center;
  }
}

@media (min-width: 0) {
  .Second-Title {
    max-width: 460px;
    position: absolute;
    opacity: 1;
    z-index: 3;
  }
}
@media (min-width: 769px) {
  .Second-Title {
    max-width: 550px;
    bottom: 55vh;
  }
}
@media (min-width: 1100px) {
  .Second-Title {
    max-width: 750px;
    bottom: 55vh;
  }
}
@media (min-width: 1442px) {
  .Second-Title {
    max-width: 1100px;
    bottom: 55vh;
  }
}

@media (min-width: 0) {
  .Second-Subtitle {
    color: #303030;
    font-size: 0.8em;
    position: absolute;
    margin-top: 7%;
  }
}
@media (min-width: 769px) {
  .Second-Subtitle {
    position: relative;
    font-size: 1.2em;
    margin-top: 5%;
  }
}
@media (min-width: 1100px) {
  .Second-Subtitle {
    margin-top: 2%;
  }
}
@media (min-width: 1442px) {
  .Second-Subtitle {
    margin-top: 1%;
  }
}

@media (min-width: 0) {
  .Second-Texture {
    height: 100vh;
    width: 100vw;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 4;
    opacity: 0.07;
    pointer-events: none;
  }
}

@media (min-width: 0) {
  .Second-Eagle {
    height: auto;
    left: calc(100vw/2 - 25px);
    max-width: 50px;
    max-height: 130px;
    position: absolute;
    top: 10vh;
    width: auto;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Second-Eagle {
    top: 15vh;
    max-width: 65px;
    left: calc(100vw/2 - 32.5px);
  }
}
@media (min-width: 1442px) {
  .Second-Eagle {
    left: calc(100vw/2 - 45px);
    max-width: 90px;
    max-height: 175px;
  }
}

@media (min-width: 0) {
  .Second-EagleAway {
    height: auto;
    left: calc(100vw/2 - 25px);
    max-width: 50px;
    max-height: 130px;
    position: absolute;
    top: 10vh;
    width: auto;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Second-EagleAway {
    top: 15vh;
    max-width: 65px;
    left: calc(100vw/2 - 32.5px);
  }
}
@media (min-width: 1442px) {
  .Second-EagleAway {
    left: calc(100vw/2 - 45px);
    max-width: 90px;
    max-height: 175px;
  }
}

@media (min-width: 0) {
  .Second-Background {
    background: url(/static/media/Second-BackgroundSmall.953510dc.jpg) repeat-x;
    background-size: cover;
    height: 100vh;
    width: 200vw;
  }
}
@media (min-width: 650px) {
  .Second-Background {
    background: url(/static/media/Second-BackgroundMedium.0676e35e.jpg) repeat-x;
    background-size: cover;
  }
}
@media (min-width: 769px) {
  .Second-Background {
    background: url(/static/media/Second-BackgroundLarge.9d931868.jpg) repeat-x;
    background-size: cover;
  }
}

@media (min-width: 0) {
  .Second-Sharing {
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 6;
    height: 10vh;
    top: 2.5%;
    right: 1.5%;
  }
}

.Second-Twitter {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 20px;
  box-sizing: border-box;
  padding: 1px 8px 1px 6px;
  background-color: #1b95e0;
  color: #fff;
  border-radius: 3px;
  font-weight: 700;
  margin-right: 6px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.Second-Twitter:hover {
  opacity: 0.5;
}
.Second-Twitter i {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  background: transparent 0 0 no-repeat;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
}

.Second-Twitter span {
  margin-left: 5px;
  font-size: 0.8em;
}

a {
  text-decoration: none;
}

.Second-Facebook {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 20px;
  box-sizing: border-box;
  padding: 2px 6px 1px 6px;
  background-color: #1877f2;
  color: #fff;
  border-radius: 3px;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  margin-right: 6px;
}
.Second-Facebook:hover {
  opacity: 0.5;
}

.Second-Facebook span {
  font-size: 0.8em;
  font-family: Helvetica;
}

.Second-Instagram {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 20px;
  box-sizing: border-box;
  padding: 2px 6px 1px 6px;
  background-image: linear-gradient(to bottom right, #515bd4, #8134af, #dd2a7b, #feda77);
  color: #fff;
  border-radius: 3px;
  font-weight: 700;
  transition: 0.3s ease-in-out;
}
.Second-Instagram:hover {
  opacity: 0.5;
}

.Second-Instagram span {
  font-size: 0.8em;
  font-family: Helvetica;
}

@media (min-width: 0) {
  .Second-InfoButtonShow {
    position: absolute;
    bottom: 2%;
    right: 1.5%;
    z-index: 6;
    background-color: Transparent;
    border: 2px solid white;
    color: white;
    padding: 7px 13px;
    text-align: center;
    text-decoration: none;
    font-size: 0.8em;
    border-radius: 50%;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
  }
  .Second-InfoButtonShow:hover {
    background-color: #0d0d0d;
    border: 3px solid #0d0d0d;
    color: white;
  }
}
@media (min-width: 1100px) {
  .Second-InfoButtonShow {
    padding: 7px 13px;
    font-size: 0.9em;
  }
}

@media (min-width: 0) {
  .Second-InfoButtonHide {
    position: absolute;
    bottom: 2%;
    right: 1.5%;
    z-index: 6;
    background-color: #0d0d0d;
    border: 3px solid #0d0d0d;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 0.5em;
    border-radius: 50%;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
  }
  .Second-InfoButtonHide:hover {
    background-color: Transparent;
    border: 2px solid black;
    color: black;
  }
}

@media (min-width: 0) {
  .Second-InfoDiv {
    display: flex;
    background-color: #FFE9DD;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    font-size: 1em;
  }
  .Second-InfoDiv p:nth-child(1) {
    font-weight: 900;
  }
  .Second-InfoDiv p:nth-child(2) {
    margin-top: 3%;
    margin-bottom: 5%;
  }
  .Second-InfoDiv:hover {
    cursor: pointer;
  }
  .Second-InfoDiv a {
    color: #F23E2E;
    transition: 0.5s;
    cursor: pointer;
  }
  .Second-InfoDiv a:hover {
    color: #9b251f;
  }
}
@media (min-width: 1100px) {
  .Second-InfoDiv {
    font-size: 1.2em;
  }
}
@media (min-width: 1442px) {
  .Second-InfoDiv {
    font-size: 1.4em;
  }
}

.Second-InfoParagraph {
  transition: 0.5s;
}
@media (min-width: 0) {
  .Second-InfoParagraph {
    margin: 0 10% 0 10%;
  }
}
@media (min-width: 769px) {
  .Second-InfoParagraph {
    margin: 0 15% 0 15%;
  }
}
@media (min-width: 1100px) {
  .Second-InfoParagraph {
    margin: 0 20% 0 20%;
  }
}
@media (min-width: 1900px) {
  .Second-InfoParagraph {
    margin: 0 25% 0 25%;
  }
}

.Second-ArrowRight {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-right: 1px;
  margin-left: -1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.Second-InfoButtonHide:hover .Second-ArrowRight {
  border: 1px solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-right: 1px;
  margin-left: -1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.Second-Background {
  -webkit-animation: slideBackground 60s linear infinite;
  animation: slideBackground 60s linear infinite;
  background-position: 0% 0%;
}

@-webkit-keyframes slideBackground {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -200vw 0%;
  }
}
@keyframes slideBackground {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -200vw 0%;
  }
}
@media (min-width: 0) {
  .Second-EagleAway {
    -webkit-animation: eagleAwaySmall 10s;
    animation: eagleAwaySmall 10s;
    transition: -webkit-animation ease-in;
    transition: animation ease-in;
    transition: animation ease-in, -webkit-animation ease-in;
  }
}
@media (min-width: 769px) {
  .Second-EagleAway {
    -webkit-animation: eagleAwayMedium 10s;
    animation: eagleAwayMedium 10s;
    transition: -webkit-animation ease-in;
    transition: animation ease-in;
    transition: animation ease-in, -webkit-animation ease-in;
  }
}
@media (min-width: 1442px) {
  .Second-EagleAway {
    -webkit-animation: eagleAwayLarge 10s;
    animation: eagleAwayLarge 10s;
    transition: -webkit-animation ease-in;
    transition: animation ease-in;
    transition: animation ease-in, -webkit-animation ease-in;
  }
}

@-webkit-keyframes eagleAwaySmall {
  from {
    left: calc(100vw/2 - 25px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
@keyframes eagleAwaySmall {
  from {
    left: calc(100vw/2 - 25px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
@-webkit-keyframes eagleAwayMedium {
  from {
    left: calc(100vw/2 - 32.5px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
@keyframes eagleAwayMedium {
  from {
    left: calc(100vw/2 - 32.5px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
@-webkit-keyframes eagleAwayLarge {
  from {
    left: calc(100vw/2 - 45px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
@keyframes eagleAwayLarge {
  from {
    left: calc(100vw/2 - 45px);
  }
  to {
    left: calc(100vw + 200px);
  }
}
.Second-Title {
  -webkit-animation: fadeTitle 4s;
  animation: fadeTitle 4s;
}

.Second-Subtitle {
  -webkit-animation: fadeSubTitle 4s;
  animation: fadeSubTitle 4s;
}

@-webkit-keyframes fadeTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeSubTitle {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeSubTitle {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Third-WrapperRed {
  color: #FFE9DD;
  background-color: #F23E2E;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  pointer-events: none;
}

@media (min-width: 0) {
  .Third-Texture {
    height: 100vh;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 5;
    pointer-events: none;
  }
}

.Third-FlagDivs {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

@media (min-width: 0) {
  .Third-FlagSmall {
    height: 120%;
    position: absolute;
    width: auto;
    top: 10vh;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Third-FlagSmall {
    display: none;
  }
}

@media (min-width: 0) {
  .Third-FlagLarge {
    display: none;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Third-FlagLarge {
    display: flex;
    height: 120%;
    position: absolute;
    width: auto;
    top: 10vh;
  }
}

@media (min-width: 0) {
  .Third-FlagDistortSmall {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 3;
  }
}
@media (min-width: 769px) {
  .Third-FlagDistortSmall {
    display: none;
  }
}

@media (min-width: 0) {
  .Third-FlagDistortLarge {
    display: none;
    z-index: 3;
  }
}
@media (min-width: 769px) {
  .Third-FlagDistortLarge {
    display: flex;
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 3;
  }
}

@media (min-width: 0) {
  .Third-Fourth-FlagLarge {
    display: none;
    z-index: 4;
  }
}
@media (min-width: 769px) {
  .Third-Fourth-FlagLarge {
    display: flex;
    height: 100%;
    width: auto;
    position: absolute;
  }
}

@media (min-width: 0) {
  .Third-Fourth-FlagSmall {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 4;
  }
}
@media (min-width: 769px) {
  .Third-Fourth-FlagSmall {
    display: none;
  }
}

@media (min-width: 0) {
  .Third-Background {
    background: url(/static/media/Fourth-BackgroundSmall.07438325.jpg);
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
}
@media (min-width: 769px) {
  .Third-Background {
    background: url(/static/media/Fourth-BackgroundLarge.2d91678f.jpg) repeat-x;
    background-size: cover;
  }
}

.Third-FlagDivs {
  -webkit-animation: moveFlag 10s;
  animation: moveFlag 10s;
}

@-webkit-keyframes moveFlag {
  0% {
    width: 50%;
  }
  30% {
    width: 100%;
  }
}
@keyframes moveFlag {
  0% {
    width: 50%;
  }
  30% {
    width: 100%;
  }
}
.Third-FlagLarge, .Third-FlagSmall {
  -webkit-animation: zoomFlag 9s;
  animation: zoomFlag 9s;
  transform-origin: 58% 21%;
  opacity: 0;
}

@-webkit-keyframes zoomFlag {
  0% {
    transform-origin: 58% 21%;
    opacity: 1;
  }
  30% {
    transform: scale(1);
  }
  97% {
    transform: scale(6.7);
    opacity: 1;
  }
  100% {
    transform: scale(6.7);
    opacity: 0;
  }
}
@keyframes zoomFlag {
  0% {
    transform-origin: 58% 21%;
    opacity: 1;
  }
  30% {
    transform: scale(1);
  }
  97% {
    transform: scale(6.7);
    opacity: 1;
  }
  100% {
    transform: scale(6.7);
    opacity: 0;
  }
}
.Third-FlagDistortLarge, .Third-FlagDistortSmall {
  -webkit-animation: flagDisort 5s;
  animation: flagDisort 5s;
}

@-webkit-keyframes flagDisort {
  0% {
    height: 148%;
    top: -11%;
  }
  70% {
    height: 100%;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@keyframes flagDisort {
  0% {
    height: 148%;
    top: -11%;
  }
  70% {
    height: 100%;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@media (min-width: 0) {
  .Third-Background {
    -webkit-animation: crossFadeSmall 20s;
    animation: crossFadeSmall 20s;
  }
}
@media (min-width: 769px) {
  .Third-Background {
    -webkit-animation: crossFadeLarge 20s;
    animation: crossFadeLarge 20s;
  }
}

@-webkit-keyframes crossFadeSmall {
  0% {
    background: url(/static/media/Third-BackgroundSmall.3ab4edde.png);
    background-size: cover;
  }
  60% {
    background: url(/static/media/Third-BackgroundSmall.3ab4edde.png);
    background-size: cover;
  }
  70% {
    background: url(/static/media/Fourth-BackgroundSmall.07438325.jpg);
    background-size: cover;
  }
}
@keyframes crossFadeLarge {
  0% {
    background: url(/static/media/Third-BackgroundSmall.3ab4edde.png);
    background-size: cover;
  }
  60% {
    background: url(/static/media/Third-BackgroundSmall.3ab4edde.png);
    background-size: cover;
  }
  70% {
    background: url(/static/media/Fourth-BackgroundSmall.07438325.jpg);
    background-size: cover;
  }
}
@-webkit-keyframes crossFadeLarge {
  0% {
    background: url(/static/media/Third-BackgroundLarge.45cecaad.png);
    background-size: cover;
  }
  60% {
    background: url(/static/media/Third-BackgroundLarge.45cecaad.png);
    background-size: cover;
  }
  70% {
    background: url(/static/media/Fourth-BackgroundLarge.2d91678f.jpg);
    background-size: cover;
  }
}
@keyframes crossFadeLarge {
  0% {
    background: url(/static/media/Third-BackgroundLarge.45cecaad.png);
    background-size: cover;
  }
  60% {
    background: url(/static/media/Third-BackgroundLarge.45cecaad.png);
    background-size: cover;
  }
  70% {
    background: url(/static/media/Fourth-BackgroundLarge.2d91678f.jpg);
    background-size: cover;
  }
}
@media (min-width: 0) {
  .Fourth-Background {
    background: url(/static/media/Fourth-BackgroundLarge.2d91678f.jpg);
    background-size: cover;
    height: 100vh;
    opacity: 1;
    width: 100vw;
    z-index: 1;
    position: absolute;
  }
}

.Fourth-Wrapper {
  background-color: #0d0d0d;
}

.Fourth-WrapperFlag {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

@media (min-width: 0) {
  .Fourth-FlagLarge {
    display: none;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Fourth-FlagLarge {
    display: flex;
    height: 100%;
    width: auto;
    position: absolute;
  }
}

@media (min-width: 0) {
  .Fourth-Texture {
    height: 100vh;
    left: 0;
    opacity: 0.07;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 4;
    pointer-events: none;
  }
}

@media (min-width: 0) {
  .Fourth-People {
    display: flex;
    justify-content: center;
    width: auto;
    height: 100%;
    position: absolute;
    z-index: 3;
  }
}

@media (min-width: 0) {
  .Fourth-FlagSmall {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .Fourth-FlagSmall {
    display: none;
  }
}

.Fourth-ArrowPerson-Red {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to right, #ee6052, #F23E2E, #9b251f);
  display: flex;
  flex-direction: column;
}

.Fourth-ArrowPerson-White {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to right, #FFE9DD, #36388C);
  display: flex;
  flex-direction: column;
}

@media (min-width: 0) {
  .Fourth-Arrow {
    align-self: flex-start;
    height: 8%;
    margin: 2% 0 0 2%;
    z-index: 5;
  }
  .Fourth-Arrow:hover {
    transition: 0.5s;
    opacity: 0.3;
    cursor: pointer;
  }
}
@media (min-width: 769px) {
  .Fourth-Arrow {
    height: 5%;
  }
}
@media (min-width: 1100px) {
  .Fourth-Arrow {
    height: 4%;
  }
}

.Fourth-Person {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
}

@media (min-width: 0) {
  .Fourth-Writing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFE9DD;
    height: 100%;
    width: 40%;
    padding-left: 5%;
    z-index: 5;
  }
  .Fourth-Writing a {
    color: #FFE9DD;
    transition: 0.5s;
    text-decoration: underline;
  }
  .Fourth-Writing a:hover {
    opacity: 0.5;
  }
}

@media (min-width: 0) {
  .Fourth-Title {
    font-weight: 900;
    font-family: "Lato", sans-serif;
    font-size: 1.9em;
    margin-top: 0;
    margin-bottom: 2%;
  }
}
@media (min-width: 1100px) {
  .Fourth-Title {
    font-size: 2.5em;
  }
}
@media (min-width: 1442px) {
  .Fourth-Title {
    font-size: 3.3em;
  }
}

@media (min-width: 0) {
  .Fourth-SubTitle {
    color: #292759;
    font-weight: 700;
    font-style: italic;
    font-size: 0.7em;
  }
  .Fourth-SubTitle p {
    margin: 0;
  }
}
@media (min-width: 1100px) {
  .Fourth-SubTitle {
    font-size: 0.9em;
  }
}
@media (min-width: 1442px) {
  .Fourth-SubTitle {
    font-size: 1.1em;
  }
}

@media (min-width: 0) {
  .Fourth-Story {
    display: flex;
    height: 20vh;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 30%;
    margin-top: 8%;
    font-size: 1em;
  }
  .Fourth-Story p:nth-of-type(1) {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
    font-size: 0.5em;
    padding: 3px;
    border: 1px solid #FFE9DD;
  }
  .Fourth-Story p:last-child {
    font-size: 0.3em;
  }
  .Fourth-Story p {
    margin-right: 5%;
    margin-bottom: 5%;
  }
}
@media (min-width: 769px) {
  .Fourth-Story p:nth-of-type(1) {
    font-size: 0.8em;
    padding: 5px;
  }
  .Fourth-Story p:last-child {
    font-size: 0.6em;
  }
  .Fourth-Story p {
    margin-bottom: 2.5%;
  }
}
@media (min-width: 1100px) {
  .Fourth-Story {
    font-size: 1em;
  }
  .Fourth-Story p:last-child {
    font-size: 0.8em;
  }
}
@media (min-width: 1442px) {
  .Fourth-Story {
    font-size: 1.2em;
  }
  .Fourth-Story p:last-child {
    font-size: 0.8em;
  }
}

.Fourth-Story::-webkit-scrollbar-track {
  background-color: #FFE9DD;
}

.Fourth-Story::-webkit-scrollbar {
  width: 4px;
}

.Fourth-Story::-webkit-scrollbar-thumb {
  background-color: #282828;
}

@media (min-width: 0) {
  .Fourth-Portrait {
    height: 65%;
    width: auto;
  }
}

.Fourth-SilhouettesDiv {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
}

.Fourth-Silhouettes {
  display: flex;
  position: absolute;
  width: calc(120vh);
  max-width: 4000px;
  height: 100vh;
  z-index: 10;
}

@media (min-width: 0) {
  .Fourth-Row8Person1 {
    position: absolute;
    z-index: 5;
    height: 3%;
    width: auto;
    top: 30.5%;
    left: 43%;
  }
  .Fourth-Row8Person1:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row8Person1-Red {
    position: absolute;
    z-index: 4;
    height: 3%;
    width: auto;
    top: 30.5%;
    left: 43%;
  }
}

@media (min-width: 0) {
  .Fourth-Row3Person2 {
    position: absolute;
    z-index: 5;
    height: 7.2%;
    width: auto;
    top: 53.5%;
    left: 47.5%;
  }
  .Fourth-Row3Person2:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row3Person2-Red {
    position: absolute;
    z-index: 4;
    height: 7.2%;
    width: auto;
    top: 53.5%;
    left: 47.5%;
  }
}

@media (min-width: 0) {
  .Fourth-Row1Person2 {
    position: absolute;
    z-index: 6;
    height: 4.5%;
    width: auto;
    top: 70.8%;
    left: 80%;
  }
  .Fourth-Row1Person2:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row1Person2-Red {
    position: absolute;
    z-index: 5;
    height: 4.5%;
    width: auto;
    top: 70.8%;
    left: 80%;
  }
}

@media (min-width: 0) {
  .Fourth-Row1Person1 {
    position: absolute;
    z-index: 6;
    height: 12%;
    width: auto;
    top: 63.5%;
    left: 17.5%;
  }
  .Fourth-Row1Person1:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row1Person1-Red {
    position: absolute;
    z-index: 5;
    height: 12%;
    width: auto;
    top: 63.5%;
    left: 17.5%;
  }
}

@media (min-width: 0) {
  .Fourth-Row6Person1 {
    position: absolute;
    z-index: 5;
    height: 4.5%;
    width: auto;
    top: 38.7%;
    left: 9%;
  }
  .Fourth-Row6Person1:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row6Person1-Red {
    position: absolute;
    z-index: 4;
    height: 4.5%;
    width: auto;
    top: 38.7%;
    left: 9%;
  }
}

@media (min-width: 0) {
  .Fourth-Row3Person1 {
    position: absolute;
    z-index: 5;
    height: 9%;
    width: auto;
    top: 52.2%;
    left: 5.5%;
  }
  .Fourth-Row3Person1:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row3Person1-Red {
    position: absolute;
    z-index: 4;
    height: 9%;
    width: auto;
    top: 52.2%;
    left: 5.5%;
  }
}

@media (min-width: 0) {
  .Fourth-Row11Person1 {
    position: absolute;
    z-index: 5;
    height: 4%;
    width: auto;
    top: 17.7%;
    left: 62%;
  }
  .Fourth-Row11Person1:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row11Person1-Red {
    position: absolute;
    z-index: 4;
    height: 4%;
    width: auto;
    top: 17.7%;
    left: 62%;
  }
}

@media (min-width: 0) {
  .Fourth-Row8Person2 {
    position: absolute;
    z-index: 5;
    height: 6%;
    width: auto;
    top: 27.8%;
    left: 86%;
  }
  .Fourth-Row8Person2:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row8Person2-Red {
    position: absolute;
    z-index: 4;
    height: 6%;
    width: auto;
    top: 27.8%;
    left: 86%;
  }
}

@media (min-width: 0) {
  .Fourth-Row6Person2 {
    position: absolute;
    z-index: 5;
    height: 2%;
    width: auto;
    top: 41.7%;
    left: 72%;
  }
  .Fourth-Row6Person2:hover {
    -webkit-animation: none;
            animation: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

@media (min-width: 0) {
  .Fourth-Row6Person2-Red {
    position: absolute;
    z-index: 4;
    height: 2%;
    width: auto;
    top: 41.7%;
    left: 72%;
  }
}

@media (min-width: 0) {
  .Fourth-ActionDiv {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
  }
}

@media (min-width: 0) {
  .Fourth-Action {
    position: absolute;
    z-index: 6;
    background-color: Transparent;
    border: none;
    color: white;
    padding: 5px 0px;
    text-align: center;
    border-bottom: 2px solid white;
    font-size: 0.7em;
    margin: 15px 20px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
  }
  .Fourth-Action:hover:before {
    visibility: visible;
    width: 100%;
  }
  .Fourth-Action:hover {
    border-bottom: none;
    color: #F23E2E;
  }
}
@media (min-width: 769px) {
  .Fourth-Action {
    border-bottom: 3px solid white;
    margin: 20px 30px;
    font-size: 0.8em;
  }
}
@media (min-width: 1100px) {
  .Fourth-Action {
    font-size: 1em;
  }
}
@media (min-width: 1442px) {
  .Fourth-Action {
    font-size: 1.2em;
  }
}

.Fourth-Action:before {
  content: "";
  position: absolute;
  top: 5;
  width: 0;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #F23E2E;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.Fourth-DirectionDiv {
  display: flex;
  height: 23vh;
  align-self: auto;
  width: calc(120vh);
  max-width: 4000px;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

@media (min-width: 0) {
  .Fourth-Direction {
    color: #FFE9DD;
    z-index: 5;
    font-size: 0.7em;
    margin: 5% 10% 0 10%;
    font-weight: 700;
    text-align: center;
  }
}
@media (min-width: 769px) {
  .Fourth-Direction {
    font-size: 0.9em;
  }
}
@media (min-width: 1100px) {
  .Fourth-Direction {
    font-size: 1.1em;
  }
}
@media (min-width: 1442px) {
  .Fourth-Direction {
    font-size: 1.3em;
  }
}
@media (min-width: 1900px) {
  .Fourth-Direction {
    font-size: 1.5em;
  }
}

@media (min-width: 0) {
  .Fourth-ActionContent {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    opacity: 0.9;
    color: white;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
    font-weight: 700;
  }
  .Fourth-ActionContent img {
    height: 40%;
    width: auto;
  }
  .Fourth-ActionContent p:nth-child(1) {
    margin: 0 20% 0 20%;
  }
  .Fourth-ActionContent p:nth-child(1) a {
    color: #F23E2E;
    transition: 0.5s;
  }
  .Fourth-ActionContent p:nth-child(1) a:hover {
    color: #36388C;
  }
  .Fourth-ActionContent button {
    background-color: Transparent;
    border: 3px solid white;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 0.7em;
    margin: 30% 0 30% 0;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
  }
  .Fourth-ActionContent button:hover {
    background-color: #F23E2E;
    border: 3px solid #F23E2E;
  }
}
@media (min-width: 769px) {
  .Fourth-ActionContent {
    font-size: 1.1em;
  }
  .Fourth-ActionContent button {
    margin: 40% 0 40% 0;
    font-size: 1em;
  }
}
@media (min-width: 1100px) {
  .Fourth-ActionContent {
    font-size: 1.3em;
  }
  .Fourth-ActionContent p:nth-child(1) {
    margin: 0 20% 0 20%;
  }
  .Fourth-ActionContent button {
    margin: 45% 0 45% 0;
    font-size: 1.2em;
  }
}
@media (min-width: 1442px) {
  .Fourth-ActionContent {
    font-size: 1.5em;
  }
  .Fourth-ActionContent p:nth-child(1) {
    margin: 0 25% 0 25%;
  }
}

@media (min-width: 0) {
  .Fourth-SubCaption {
    font-size: 0.7em;
    margin: 0 20% 0 20%;
  }
  .Fourth-SubCaption a {
    color: white;
    text-decoration: underline;
    transition: 0.5s;
  }
  .Fourth-SubCaption a:hover {
    color: #F23E2E;
  }
}

@media (min-width: 0) {
  .Fourth-Home-FadeIn, .Fourth-Home-FadeOut {
    display: flex;
    position: absolute;
    z-index: 6;
    background-color: Transparent;
    border: 2px solid white;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 0.5em;
    bottom: 3.5%;
    right: 2%;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.4;
  }
  .Fourth-Home-FadeIn:hover, .Fourth-Home-FadeOut:hover {
    background-color: #0d0d0d;
    border: 3px solid #0d0d0d;
    color: white;
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .Fourth-Home-FadeIn, .Fourth-Home-FadeOut {
    padding: 5px 10px;
    font-size: 0.5em;
  }
}
@media (min-width: 1100px) {
  .Fourth-Home-FadeIn, .Fourth-Home-FadeOut {
    padding: 10px 20px;
    font-size: 0.7em;
  }
}
@media (min-width: 1442px) {
  .Fourth-Home-FadeIn, .Fourth-Home-FadeOut {
    padding: 12px 22px;
    font-size: 0.9em;
  }
}

@media (min-width: 0) {
  .Fourth-FAQButton {
    position: absolute;
    top: 2.5%;
    right: 1.5%;
    z-index: 6;
    background-color: Transparent;
    border: 2px solid white;
    color: white;
    padding: 7px 11px;
    text-align: center;
    font-size: 0.8em;
    border-radius: 50%;
    font-weight: 900;
    cursor: pointer;
    transition: 0.5s;
  }
  .Fourth-FAQButton:hover {
    border: 3px solid #F23E2E;
    color: #F23E2E;
  }
}
@media (min-width: 769px) {
  .Fourth-FAQButton {
    font-size: 0.9em;
    border: 3px solid white;
  }
}
@media (min-width: 1100px) {
  .Fourth-FAQButton {
    padding: 7px 13px;
    font-size: 1.2em;
  }
}
@media (min-width: 1442px) {
  .Fourth-FAQButton {
    font-size: 1.4em;
  }
}

@media (min-width: 0) {
  .Fourth-FAQContent {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: black;
    opacity: 0.9;
    color: white;
    font-size: 1em;
    overflow-y: scroll;
    cursor: pointer;
    font-weight: 300;
  }
  .Fourth-FAQContent p:nth-of-type(1) {
    align-items: center;
    font-weight: 900;
    font-size: 2.2em;
    margin: 7% 0 0 0;
    text-align: center;
  }
  .Fourth-FAQContent p:nth-of-type(2) {
    align-items: center;
    margin: 0;
    color: white;
    font-weight: 700;
    font-size: 1.2em;
    text-align: center;
  }
  .Fourth-FAQContent p:nth-of-type(3) {
    font-size: 0.8em;
    font-style: italic;
    text-align: center;
    margin: 4% 20% 0 20%;
    color: #F23E2E;
    font-weight: 700;
  }
  .Fourth-FAQContent p:nth-of-type(3) a {
    color: #F23E2E;
    text-decoration: underline;
    transition: 0.5s;
  }
  .Fourth-FAQContent p:nth-of-type(3) a:hover {
    color: #9b251f;
  }
  .Fourth-FAQContent p:nth-of-type(4), .Fourth-FAQContent p:nth-of-type(7), .Fourth-FAQContent p:nth-of-type(9), .Fourth-FAQContent p:nth-of-type(11), .Fourth-FAQContent p:nth-of-type(14) {
    font-weight: 700;
    text-align: left;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  .Fourth-FAQContent p:nth-of-type(16) {
    margin-bottom: 7%;
  }
  .Fourth-FAQContent p {
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 1%;
    margin-top: 1%;
    text-align: left;
  }
}
@media (min-width: 769px) {
  .Fourth-FAQContent {
    font-size: 1.1em;
  }
}
@media (min-width: 1100px) {
  .Fourth-FAQContent {
    font-size: 1.3em;
  }
}
@media (min-width: 1442px) {
  .Fourth-FAQContent {
    font-size: 1.5em;
  }
  .Fourth-FAQContent p {
    margin-right: 25%;
    margin-left: 25%;
    margin-bottom: 0.5%;
    margin-top: 0.5%;
  }
  .Fourth-FAQContent p:nth-of-type(3) {
    margin: 4% 25% 0 25%;
  }
  .Fourth-FAQContent p:nth-of-type(4), .Fourth-FAQContent p:nth-of-type(7), .Fourth-FAQContent p:nth-of-type(9), .Fourth-FAQContent p:nth-of-type(11), .Fourth-FAQContent p:nth-of-type(14) {
    margin-bottom: 1%;
    margin-top: 4%;
  }
}

.Fourth-People, .Fourth-Row8Person1-Red, .Fourth-Row3Person2-Red, .Fourth-Row1Person2-Red, .Fourth-Row1Person1-Red, .Fourth-Row6Person1-Red, .Fourth-Row3Person1-Red,
.Fourth-Row11Person1-Red, .Fourth-Row8Person2-Red, .Fourth-Row6Person2-Red {
  -webkit-animation: fadePeople 3s;
  animation: fadePeople 3s;
}

@-webkit-keyframes fadePeople {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadePeople {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-Row3Person1, .Fourth-Row11Person1, .Fourth-Row6Person2, .Fourth-Row1Person2 {
  -webkit-animation: fadePeople 3s, personBlink1 ease-in-out infinite 1.8s;
  animation: fadePeople 3s, personBlink1 ease-in-out infinite 1.8s;
  -webkit-animation-delay: 0ms, 3s;
          animation-delay: 0ms, 3s;
}

@-webkit-keyframes personBlink1 {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes personBlink1 {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.Fourth-Row8Person1, .Fourth-Row6Person1, .Fourth-Row8Person2, .Fourth-Row3Person2, .Fourth-Row1Person1 {
  -webkit-animation: fadePeople 3s, personBlink2 ease-in-out infinite 1.8s;
  animation: fadePeople 3s, personBlink2 ease-in-out infinite 1.8s;
  -webkit-animation-delay: 0ms, 3s;
          animation-delay: 0ms, 3s;
}

@-webkit-keyframes personBlink2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes personBlink2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-Person, .Fourth-Title, .Fourth-SubTitle {
  -webkit-animation: personAppear 3s;
  animation: personAppear 3s;
}

@-webkit-keyframes personAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes personAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-Story {
  -webkit-animation: storyAppear 7s;
  animation: storyAppear 7s;
}

@-webkit-keyframes storyAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes storyAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-Direction {
  -webkit-animation: directionAppear 4s;
  animation: directionAppear 4s;
}

@-webkit-keyframes directionAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes directionAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-Action {
  -webkit-animation: actionAppear 3s;
  animation: actionAppear 3s;
}

@-webkit-keyframes actionAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes actionAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Fourth-ActionContent, .Fourth-FAQContent {
  -webkit-animation: actionAppearContent 1s;
  animation: actionAppearContent 1s;
}

@-webkit-keyframes actionAppearContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes actionAppearContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
.Fourth-Home-FadeIn {
  -webkit-animation: homeFadeIn 3s;
  animation: homeFadeIn 3s;
}

@-webkit-keyframes homeFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes homeFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
.Fourth-Home-FadeOut {
  -webkit-animation: homeFadeOut 2s;
  animation: homeFadeOut 2s;
  opacity: 0;
}

@-webkit-keyframes homeFadeOut {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}
@keyframes homeFadeOut {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}

/*# sourceMappingURL=newmain.css.map */

